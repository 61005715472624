.pano {
  position: absolute;
  // background-color: black;
  // right: 45px;
  left: 16px;
  bottom: 20px;
  border-radius: 10px;

  .custom-hotspot {
    pointer-events: none;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .pnlm-container {
    border-radius: 10px;
    width: 250px !important;
    height: 140px !important;
    background: none !important;
    .pnlm-controls-container {
      // left: 216px;
      right: 0;
      .pnlm-controls {
        border-radius: 50%;
      }
      .pnlm-sprite {
        // filter: invert(55%) sepia(13%) saturate(2649%) hue-rotate(331deg)
        //   brightness(98%) contrast(81%);
      }
      .pnlm-fullscreen-toggle-button {
      }
    }
  }
  .pnlm-render-container {
    canvas {
      border-radius: 10px;
    }
  }
  p {
    color: white;
  }
  .pnlm-ui {
    .pnlm-load-box {
      width: 95px;
      height: 95px;
      top: 64%;
      left: 64%;
      display: block;
      color: bisque;
      p {
        font-size: small;
      }
      .pnlm-lmsg {
        display: none;
      }
      .pnlm-lbar {
        width: 50px;
        .pnlm-lbar-fill {
          width: 50% !important;
        }
      }
    }
    // .pnlm-compass {
    //   background-size: 27px;
    // }
  }
  // .pnlm-render-container {
  //   height: 50%;
  //   width: 50%;
  //   // .pnlm-preview-img {
  //   //   height: 50%;
  //   //   width: 50%;
  //   // }
  // }
}
.clicked-class {
  position: relative;
  z-index: 1;
  left: 0;
  top: -74px;
  border-radius: 0;
  .pnlm-container {
    border-radius: 0;
    width: 223vh !important;
    height: 59vh !important;
    background: none !important;
    .pnlm-controls-container {
      left: 96%;
      top: 88%;
      .pnlm-controls {
        border-radius: 50%;
      }
      // .pnlm-fullscreen-toggle-button {
      //   filter: invert(55%) sepia(13%) saturate(2649%) hue-rotate(331deg)
      //     brightness(98%) contrast(81%);
      // }
      .pnlm-fullscreen-toggle-button-inactive {
        background-position: 2px -50px;
      }

      .pnlm-fullscreen-toggle-button,
      .pnlm-orientation-button,
      .pnlm-hot-spot-debug-indicator {
        width: 30px;
        height: 30px;
      }
    }
  }
  .pnlm-render-container {
    canvas {
      border-radius: 0px;
    }
  }
  .pnlm-ui {
    .pnlm-load-box {
      width: 95px;
      height: 95px;
      top: 64%;
      left: 64%;
      p {
        font-size: small;
      }
      .pnlm-lbar {
        width: 50px;
        .pnlm-lbar-fill {
          width: 50% !important;
        }
      }
    }
    // .pnlm-compass {
    //   background-size: 27px;
    // }
  }
}
.selected-pano {
  position: absolute;
  z-index: 1;

  .pnlm-container {
    border-radius: 10px;
    width: 500px !important;
    height: 500px !important;
    background: none !important;

    .pnlm-controls-container {
      left: 216px;
      .pnlm-controls {
        border-radius: 50%;
        filter: invert(55%) sepia(13%) saturate(2649%) hue-rotate(331deg)
          brightness(98%) contrast(81%);
      }

      // .pnlm-sprite {
      //   background-color: aquamarine;
      //   // filter: invert(55%) sepia(13%) saturate(2649%) hue-rotate(331deg)
      //   //   brightness(98%) contrast(81%);
      // }
      .pnlm-fullscreen-toggle-button-inactive {
        // background-color: aquamarine;
        // filter: invert(55%) sepia(13%) saturate(2649%) hue-rotate(331deg)
        //   brightness(98%) contrast(81%);
      }
    }
  }
  .pnlm-ui {
    .pnlm-load-box {
      width: 95px;
      height: 95px;
      top: 64%;
      left: 64%;
      p {
        font-size: small;
      }
      .pnlm-lbar {
        width: 50px;
        .pnlm-lbar-fill {
          width: 50% !important;
        }
      }
    }
    // .pnlm-compass {
    //   background-size: 27px;
    // }
  }
}

.no-pano {
  background-color: black;
}

.select {
  position: absolute;
  left: 32%;
  bottom: 20px;
  border-radius: 10px;
  // animation: 2s slide;
  // @keyframes slide {
  //   from {
  //     margin-left: 100%;
  //   }
  //   to {
  //     margin-left: 0%;
  //   }
  // }

  .pnlm-container {
    border-radius: 10px;
    width: 250px !important;
    height: 140px !important;
    background: none !important;
    .pnlm-controls-container {
      left: 215px;
      .pnlm-controls {
        border-radius: 50%;
      }
      .pnlm-sprite {
        // filter: invert(55%) sepia(13%) saturate(2649%) hue-rotate(331deg)
        //   brightness(98%) contrast(81%);
      }
      .pnlm-fullscreen-toggle-button {
      }
    }
  }
  .pnlm-render-container {
    canvas {
      border-radius: 10px;
    }
  }
  p {
    color: white;
  }
  .pnlm-ui {
    .pnlm-load-box {
      width: 95px;
      height: 95px;
      top: 64%;
      left: 64%;
      p {
        font-size: small;
      }
      .pnlm-lbar {
        width: 50px;
        .pnlm-lbar-fill {
          width: 50% !important;
        }
      }
    }
    // .pnlm-compass {
    //   background-size: 27px;
    // }
  }
}

.custom-hotspot {
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;
}
