.autocomplete-search {
  position: relative;
  z-index: 1;
  background-color: white;
  margin: 15px;
  border-radius: 30px;
  // width: 30%;
  // padding: 10px;
  //   height: 9%;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
  .search-box {
    // width: 68vh;

    padding: 11px 0px;
    img {
      height: 20px;
      width: 20px;
      object-fit: contain;
      // margin: 12px;
    }

    .vline {
      border-left: 0.6px solid #8f95b25c;

      height: 22px;
      // margin: 12px;
      // position: absolute;
      // right: 54px;
    }
    input {
      width: -webkit-fill-available;
    }
    input[type="text"] {
      // padding: 12px;
      border: none;
      //placeholder color:#A8A8A8
    }
    input[type="text"]:placeholder-shown {
      font-size: 14px;
    }
    input[value] {
      color: #454545;
      font-size: 14px;
      font-weight: 600;
    }
    textarea:focus,
    input:focus {
      outline: none;
    }
    // *:focus {
    //   outline: none;
    // }
  }
}

.search-nodata {
  position: absolute;
  background-color: white;
  top: 34px;
  left: 16px;
  width: 29.8vw;
  height: 95vh;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
}

.search-data {
  position: absolute;
  background-color: white;
  top: 34px;
  left: 16px;
  overflow-y: scroll;
  width: 29.8vw;
  height: 95vh;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
  ul {
    list-style: none;
    margin: 0;
    padding: 25px 13px 0px 7px;
    position: relative;
    top: 23px;
    li {
      font-size: small;
      padding-bottom: 20px;

      img {
        object-fit: contain;
        height: 20px;
        width: 20px;
      }
      span {
        font-family: "Montserrat", sans-serif;
        font-size: 13px;
        font-weight: 600;
        text-decoration: none;
        color: #000000;
        position: absolute;
        padding-right: 16px;
        left: 48px;
      }
    }
  }
}
.offcanvas {
  .offcanvas-body {
    ul {
      li {
        font-size: 14px;
        font-weight: 600;
        padding-bottom: 5px;
        a {
          font-size: 14px;
          font-weight: 600;
          padding-bottom: 5px;
          color: black;
          text-decoration: none;
        }
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .search-data {
    width: 91vw;
  }
}
/* width */
::-webkit-scrollbar {
  width: 5px;
  vertical-align: -0.2em;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #91a4b7;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #718eac;
}

::-webkit-progress-value {
  background-color: #812c19;
}
